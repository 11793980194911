export const footerData = [  
  {
    caption: "About",
    links: [
      {
        route: "/avtomobile",
        name: "Avtomobile",
      },
      {
        route: "/phones",
        name: "Telefonlar",
      },
      {
        route: "/oziqovqat",
        name: "Oziq-ovqat",
      },
      {
        route: "/stanoklar",
        name: "Stanoklar",
      },
      {
        route: "/maishiytexnikalar",
        name: "Maishiy texnikalar",
      },
      {
        route: "/mebellar",
        name: "Mebellar",
      },
    ],
  },
];
