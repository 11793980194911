import img1 from './../assets/4.png' 
import img2 from './../assets/10.png' 
import img3 from './../assets/8.png' 
import img4 from './../assets/9.png' 
// import img5 from './../assets/visit_front.png' 


export const bannerCarousel_data = [
    img1,
    img2, 
    img3, 
    img4,
    // img5
]
