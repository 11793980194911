import React from 'react'
import "./Login.css"

function Login() {
  return (
    <div>
      <h1>Login</h1>
    </div>
  )
}

export default Login
