import React from 'react'
import './Cart.css'
function Cart() {
  return (
    <div>
      <h1>Cart</h1>
    </div>
  )
}

export default Cart
