export const categories = [
    {
        id: 0,
        title: "Barchasi",
        link: "/"
    },
    {
        id: 1,
        title: "Avtomobillar",
        link: "avtomobiles"
    },
    {
        id: 2,
        title: "Maishiy Texnika",
        link: "appliances"
    },
    {
        id: 3,
        title: "Elektron jihozlar",
        link: "electronics"
    },
    {
        id: 4,
        title: "Uy-joylar",
        link: "houses"
    },
    {
        id: 5,
        title: "Mebellar",
        link: "mebels"
    },
    {
        id: 6,
        title: "Qurilish mollari",
        link: "xostovarlar"
    },
    {
        id: 7,
        title: "Chorva mollari",
        link: "chorva"
    },
]